import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
//import UpcomingEvents from './UpcomingEvents'
//import TheologicalInsights from '../TheologicalInsights';
import LatestCalendar from '../upcoming/LatestCalendar';
import { Link } from 'react-router-dom';
import AboutUsHomepageView from '../../about/AboutUsHomepageView';

export default function UpcomingMain() {
  return (
    <div className='caroselrow mr-2' style={{height:"560px", overflow:"scroll"}}>
        <Row>
            <Col md={4}>
            <LatestCalendar />
          {/*}  <UpcomingEvents />*/}
          
            </Col>
            <Col md={8}>
            {/*<TheologicalInsights />*/}
            <div className="">
            <div>
                <div className="text-center mx-auto wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                    <h5 className="text-primary">VIDYAJYOTI COLLEGE</h5>
                    <h1 className='text-dark-main'>Principal's Desk</h1>
                </div>
      <AboutUsHomepageView />
      <Button className='text-white' style={{marginTop: "-7.5%", marginLeft:"15%"}} variant="primary" type="submit" ><Link to="/about/principalmessage"><a href="#" className='text-white' >Read More...</a></Link></Button>
      </div>
      </div>
            </Col>
        </Row>
    </div>
  )
}
